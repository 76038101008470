import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
import { requireAuth } from "./../util/auth";
// import { Modal } from "@material-ui/core";
import KeepMountedModal from "../components/KeepMountedModal";
// import Client from 'shopify-buy';

// const client = Client.buildClient({
//   storefrontAccessToken: process.env.REACT_STOREFRONT_API_CLIENT_TOKEN, 
//   domain: 'open-grid-test-store.myshopify.com',
//   'Content-Type': 'application/graphql'
// });

// console.log("CLIENT: ", client);


function IndexPage(props) {




  return (
    <>
      {/* {window.self === window.top ? (window.location.replace('https://complexland.com'))
      :
        (<> */}
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product."
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText="Enter"
        buttonColor="primary"
        // buttonPath="/pricing"
      />
      <KeepMountedModal />
      <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
          />
        </>
      // )}</>
  );
}

export default requireAuth(IndexPage);
