import React from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Script from '@gumgum/react-script-tag';
import IconButton from '@material-ui/core/IconButton';
import {useEvent} from '../hooks';
import ProductModal from './ProductModal';
import PhotoBooth from './PhotoBooth';
import ARModal from './ARModal';
import PageLoader from './PageLoader';

const style = {
  position: 'absolute',
  top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(255,255,255,0.7)',
//   transform: 'translate(-50%, -50%)',
  width: '100%',
  p: 0,
};

const opengridCanvasStyle = {
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    background: '#231F20'
}

const ogContainerStyle = {
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '100 !important',
    height: '100vh',
    width: '100vw',
}


const buildConfig = (filename) => {
    return {
        dataUrl: `./Build/Build/${filename}.data`,
        frameworkUrl: `./Build/Build/${filename}.framework.js`,
        codeUrl: `./Build/Build/${filename}.wasm`,
        loaderUrl: `./Build/Build/Build.loader.js`,
        streamingAssetsUrl: `./Build/StreamingAssets`,
        companyName: "Open Grid",
        productName: "opengrid_retail",
        productVersion: "0.2",
    }
}

function ChildModal(props) {
  const { handlePerformance } = props;
    const [open, setOpen] = React.useState(false);
    const [whichModal, setWhichModal] = React.useState(null);
    const handleOpen = (modal) => {
        setWhichModal(modal);
      setOpen(true);
    handlePerformance();
    };
    const handleClose = () => {
      setOpen(false);
    handlePerformance();
    };


    useEvent('open_web_tshirt_right', () => handleOpen('product')); 
    useEvent('open_web_tshirt_center', () => handleOpen('product')); 
    useEvent('open_web_tshirt_left', () => handleOpen('product')); 
    useEvent('modal_photobooth', () => handleOpen('photobooth')); 
    useEvent('open_web_ar_tryon', () => handleOpen('ar')); 

    // console.debug('Performance: ', window?.performance);
  
  
    return (
      <React.Fragment>
        {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
        <Modal
        //   hideBackdrop
          open={open}
                onClose={handleClose}
                // style={{ backgroundColor: 'rgba(255,255,255,0.7'}}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
                <Box sx={{
                    position: 'absolute', width: '100vw', height: (whichModal === 'photobooth' || whichModal === 'ar') ? '100vh' : '70vh',
                    background: 'transparent', bottom: (whichModal === 'photobooth' || whichModal === 'ar') ? '-100vh' : '-70vh', left: 0,
                    transform: 'translate(0%, -100%)', transitionDuration: '2000ms'
                }}>
                    
                    {whichModal === 'product' && <ProductModal closeModal={handleClose} />}
                    {whichModal === 'photobooth' && <PhotoBooth closeModal={handleClose} />}
                    {whichModal === 'ar' && <ARModal closeModal={handleClose} />}
            {/* <Button onClick={handleClose}>Close Child Modal</Button> */}
          </Box>
        </Modal>
      </React.Fragment>
    );
}
  
export default function KeepMountedModal(props) {
    const [open, setOpen] = React.useState(false);
    const [build, setBuild] = React.useState(null);
    const [scene, setScene] = React.useState(null);
    const [openGridHasLoaded, setOpenGridHasLoaded] = React.useState(false);

  const [perf, setPerf] = React.useState({ limit: 0, used: 0, percentage: 0 })
  
  const handleOpen = () => {
    setOpen(true);
    handlePerformance();
  }
  const handleClose = () => {
    setOpen(false);
    handlePerformance();
  }

  const handlePerformance = () => {
    const mem = window?.performance?.memory;
    if (mem) {
      setPerf({
        limit: mem.jsHeapSizeLimit,
        used: mem.totalJSHeapSize,
        percentage: Math.round(mem.totalJSHeapSize / mem.jsHeapSizeLimit * 100),
      });
    }
  }

  React.useEffect(() => {
    

        if (openGridHasLoaded) {
          build.SendMessage("GameController", "SetMobileMode", "1");
          handlePerformance();
        //   if (teleportId) setTimeout(() => teleport(teleportId), 100);
        }
    }, [openGridHasLoaded]);

    React.useEffect(() => {
        if (build && scene) {
            console.debug('Scene: ', scene)
            build.SendMessage("SceneIndex", "SelectScene", scene);
        }
    }, [scene]);
    
    const loadScene = (id) => build?.SendMessage("SceneIndex", "SelectScene", id);

  useEvent('load_ready', () => {
    setScene('main');
    loadScene("main");
    handlePerformance();
  });
    useEvent('return_to_website', handleClose); 
  useEvent('loaded_ui_manager', () => {
    setOpenGridHasLoaded(true);
    handlePerformance();
  }); 
    useEvent('open_web_art_left', () => window.open('https://opensea.io/assets/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d/2087', '_blank'));
    useEvent('open_web_art_center', () => window.open('https://opensea.io/assets/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/8857', '_blank'));
    useEvent('open_web_art_right', () => window.open('https://opensea.io/assets/0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb/3100', '_blank'));

    const onLoad = () => {
      const res = buildConfig('Build');
      handlePerformance();

      
        setTimeout(() =>
            window.createUnityInstance(document.getElementById('opengrid-canvas'), res)
                .then((buildInstance) => setBuild(buildInstance))
                .catch((message) => alert(message)),
            500);   
    }  
    
    return (
        <div style={{ textAlign: 'center'}}>
            <Button onClick={handleOpen}>Enter</Button>
            {/* <Button onClick={() => loadScene('store')}  >Enter Store</Button> */}
            {/* <Button onClick={() => loadScene('block')}  >Enter Gallery</Button> */}
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
        
          <Box sx={style}>
            {perf.limit !== 0 && <div><Box style={{ zIndex: 1000000, position: 'fixed' }}><Typography variant="body1" color={openGridHasLoaded ? 'white' : 'black'} >Limit: {perf.limit} | Used: {perf.used} | {perf.percentage}%</Typography></Box></div>}
                    <Script type="text/javascript" src={'Build/Build/Build.loader.js'} onLoad={() => onLoad()} />
            <ChildModal handlePerformance={handlePerformance}/>
                <div style={ogContainerStyle}>
                    {!scene && (<PageLoader height={'100vh'} style={{ zIndex: 100000}}/>)}
                    <canvas id="opengrid-canvas" style={opengridCanvasStyle} />
                </div>
              </Box>
          
        </Modal>
        </div>
    );
}