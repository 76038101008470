import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const ARModal = (props) => {
  const { closeModal } = props;

  return (
    <div style={{ margin: 'auto', textAlign: 'center', width: '100%', minWidth: '300px', height: '100vh', paddingTop: '6vh', border: 'none', backgroundColor: 'white' }}>
      <IconButton style={{ position: 'fixed', top: '5px', right: '5px'}} onClick={closeModal}>
        <CloseIcon />
      </IconButton>
      <iframe 
        style={{ width:'100%', height: '100%', border: 'none', zIndex: 0, margin: 'auto' }}
        src="https://opengrid-ar-tryon.web.app/" 
        // src="https://demo.opengrid.xyz/" 
        allow="camera; autoplay; encrypted-media; accelerometer; gyroscope;"
        allowvr="yes"/>
    </div>);

};

export default ARModal;
